<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
            v-content
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Conversaciones pendientes')}}
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-layout(row wrap v-if="pending.length")
                            v-list(three-line v-for="pending in pending" :key="pending.uuid")
                                v-list-tile
                                    v-list-tile-avatar
                                        v-avatar.grey.lighten-4(size='27')
                                            img(v-if="photoUrl(pending.photo).length" :src="photoUrl(pending.photo)")
                                            v-gravatar(v-else :email="pending.email")
                                    v-list-tile-content
                                        v-list-tile-title {{ pending.names }}
                                        v-list-tile-sub-title 
                                            b.d-block {{ pending.network }}
                                            small {{$t('Fecha de aviso')}}: {{ pending.pending_date }}
                                    v-list-tile-action(@click="$refs.notes.open(npending(pending))")
                                        v-icon(color="primary") question_answer
                            v-flex(xs12)
                                infinite-loading(:infinite="infinite" ref="infinite")
                        .not-found(v-else)
                            h3 {{$t('No se encontraron conversaciones pendientes')}}
        notes(ref="notes" @success="fetch")
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'

    export default {
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Conversaciones pendientes')
            }
        },
        data() {
            return {
                preload: false,
                pending: [],
                limit : this.$root.limit,
                offset: 0,
            }
        },
        created(){
            this.fetch()
        },
        methods: {
            fetch(infinite = false){

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.preload = true
                    this.pending = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset
                }

                this.$api(this, (xhr) => {
                    xhr.get('/pending', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        if(infinite){
                            if(data.response){
                                this.pending = this.pending.concat(data.data);
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.pending = (data.response ? data.data : [])
                        }
                    }).catch(() => {})
                })
            },
            infinite(){
                setTimeout(() => this.fetch(true) , 500)
            },
            photoUrl(photo){

                if(photo != null && photo.length){
                    return config.uploaduser + photo
                }
                return ''
            },
            npending(pending){
                return Object.assign(pending, {uuid: pending.userid, pending: pending})
            }
        }
    }
</script>